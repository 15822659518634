@import '@/assets/css/index.scss';

.travelFormActivity {
    padding: 3px 0;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background-color: var(--color-wildSand);
}

.activityItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    height: 60px;
    padding: 0 $content-padding;
    border-bottom: 1px solid var(--color-concrete);
    cursor: pointer;
}

.activityItemCount {
    width: 31px;
    height: 18px;
    background-color: var(--color-concrete);
    border-radius: 10px;
    color: var(--color-nobel);
    font-size: $font-size-extra-small;
}
