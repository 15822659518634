@import '@/assets/css/index.scss';

.submitButtonContainer {
    width: 100%;
    position: fixed;
    bottom: $safe-area-bottom;
    padding: 0 $content-padding $content-padding--large $content-padding;
    background-color: var(--color-white);
    z-index: 2;

    :global(body.fixed) & {
        bottom: 0;
    }
}

.overlayContent {
    padding-bottom: calc(80px + $safe-area-bottom);
}
